<template>
	<div style="padding-bottom: 10px;">
		<el-form :inline="true" :model="form" class="demo-form-inline">
			<el-form-item label="状态">
				<el-select v-model="form.status" placeholder="请选择状态">
					<el-option label="全部" value="" seleted></el-option>
					<el-option label="正常" value="0"></el-option>
					<el-option label="异常" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="">
				<el-input style="width:280px" v-model="form.searchValue" placeholder="姓名模糊查询"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="danger" icon="el-icon-search" @click="doSearch">查询</el-button>
			</el-form-item>
			<el-form-item style="margin-left: 50px;">
				<el-button type="success" icon="el-icon-plus" @click="handleAdd()">添加医生</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" border style="width: 100%" :row-class-name="tableRowClassName">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-form label-position="left" inline class="table-expand">
						<el-form-item label="姓名">
							<span>{{ scope.row.name }}</span>
						</el-form-item>
						<el-form-item label="科室">
							<span>{{ scope.row.category }}</span>
						</el-form-item>
						<el-form-item label="状态">
							<span>{{ scope.row.status>0?'异常':'正常' }}</span>
						</el-form-item>
						<el-form-item label="手机号">
							<span>{{ scope.row.supplierphone }}</span>
						</el-form-item>
						<el-form-item label="身份">
							<span>{{ scope.row.suppliercontacts }}</span>
						</el-form-item>
						<el-form-item label="地址">
							<span>{{ scope.row.supplieraddress+' '+scope.row.supplieraddressdetail }}</span>
						</el-form-item>
						<el-form-item label="照片">
							<img :src="scope.row.img" width="100px" />
						</el-form-item>
						<el-form-item label="简介">
							<span>{{ scope.row.remark }}</span>
						</el-form-item>
						<el-form-item label="创建人">
							<span>{{ scope.row.creator }}</span>
						</el-form-item>
						<el-form-item label="创建时间">
							<span>{{ getDateStr(scope.row.time) }}</span>
						</el-form-item>
						<el-form-item label="预留信息">
							<span>{{ scope.row.suppliername }}</span>
						</el-form-item>
					</el-form>
			</template>
			</el-table-column>
			<el-table-column label="照片" width="80px">
				<template slot-scope="scope">
					<img :src="scope.row.img" @click="viewImg(scope.row.img)" height="40px" />
				</template>
			</el-table-column>
			<el-table-column prop="name" label="姓名" width="110px"></el-table-column>
			<el-table-column prop="category" label="科室"></el-table-column>
			<el-table-column label="状态" width="80px" :formatter="getStatus"></el-table-column>
			<el-table-column prop="supplierphone" label="手机号" width="120px"></el-table-column>
			<el-table-column prop="suppliercontacts" label="身份" width="120px"></el-table-column>
			<el-table-column prop="creator" label="创建人" width="100px"></el-table-column>
			<el-table-column label="创建时间" width="170px">
				<template slot-scope="scope">
					<span>{{ getDateStr(scope.row.time) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="100px">
				<template slot-scope="scope">
					<el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
					<el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding-top: 10px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10,15,20,25,30]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize">
			</el-pagination>
		</div>
		<doctorAdd ref="addview" @addsuccess="addSuccess" />
		<doctorEdit ref="editview" @savesuccess="addSuccess" />
		<el-dialog width="600px" title="医生照片大图" :visible.sync="dialogImage" append-to-body>
			<img :src="imageUrl" width="100%" />
		</el-dialog>
	</div>
</template>

<script>
import doctorAdd from './doctorAdd.vue'
import doctorEdit from './doctorEdit.vue'
export default {
	name: 'doctors',
	components: {
		doctorAdd, doctorEdit
	},
	props: [],
	data() {
		return {
			currentPage: 1,
			pageSize: 10,
			totalPage: 1,
			totalSize: 1,
			form: {
				searchValue: '',
				status: ''
			},
			dialogImage: false,
			imageUrl: '',
			tableData: []
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		viewImg(imgUrl){
			this.imageUrl = imgUrl;
			this.dialogImage = true;
		},
		getDateStr(val){
			if(!val||val==""){
				return '';
			}
			let d = new Date(val);
			let year = d.getFullYear();
			let month = d.getMonth() + 1;
			month = month<10 ? '0'+month : month;
			let day = d.getDate();
			day = day<10 ? '0'+day : day;
			let hour = d.getHours();
			hour = hour<10 ? '0'+hour : hour;
			let minu = d.getMinutes();
			minu = minu<10 ? '0'+minu : minu;
			let sec = d.getSeconds();
			sec = sec<10 ? '0'+sec : sec;
			return year+"-"+month+"-"+day+" "+hour+":"+minu+":"+sec;
		},
		getStatus(row){
			let st = parseInt(row.status, 10);
			return st>0 ? '异常' : '正常';
		},
		tableRowClassName({row}) {
			if (row.status == 1) {
				return 'reject-row';
			} 
			return '';
		},
		doSearch(){
			this.currentPage=1;
			this.loadData();
		},
		loadData(){
			let _query = [
				"currentPage="+this.currentPage,
				"pageSize="+this.pageSize,
				"name="+this.form.searchValue,
				"status="+this.form.status
			].join("&")
			this.$axios.get('/devices/getlist?'+_query).then(res => {
				// console.log(res);
				if(res.status==200){
					this.totalPage = res.data.data.totalPage;
					this.totalSize = res.data.data.totalSize;
					this.tableData = res.data.data.list;
				}
			});
		},
		handleSizeChange(val){
			this.pageSize = val;
			this.loadData();
		},
		handleCurrentChange(val){
			this.currentPage =val;
			this.loadData();
		},
		handleDelete(row){
			let _this = this;
			this.$confirm('确定要删除“'+row.category+' '+row.name+'医生”的信息吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios.delete('/devices?id='+row.id).then(res => {
					if(res.status==200&&res.data.code==200){
						_this.$message({
							type: 'success',
							message: '删除成功!'
						});
						_this.loadData();
					}
				});
			}).catch(() => {
				//
			});
		},
		handleAdd(){
			this.$refs['addview'].showAdd();
		},
		handleEdit(row){
			this.$refs['editview'].showEdit(row);
		},
		addSuccess(){
			this.currentPage = 1;
			this.loadData();
		}
	}
}
</script>

<style>
	.el-table .reject-row {
		background: #ffbaba;
	}
	.el-table .success-row {
		background: #f0fde9;
	}
	.table-expand {
		font-size: 0;
	}
	.table-expand label {
		width: 90px;
		color: #99a9bf;
	}
	.table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
	.el-table__expanded-cell{background-color:#f5f7fa;}
	
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 150px;
		height: 150px;
		line-height: 150px;
		text-align: center;
	}
	.avatar {
		width: 150px;
		height: 150px;
		display: block;
	}
</style>
