<template>
	<el-dialog title="新增医生" :visible.sync="dialogAddVisible" append-to-body>
		<el-form ref="form" :model="form" label-width="80px" label-position="left" style="width: 640px;margin: 0 auto;">
			<el-form-item label="姓名: ">
				<el-input v-model="form.name"></el-input>
			</el-form-item>
			<el-form-item label="科室: ">
				<el-input v-model="form.category"></el-input>
			</el-form-item>
			<el-form-item label="简介: ">
				<el-input type="textarea" v-model="form.remark"></el-input>
			</el-form-item>
			<el-form-item label="状态: ">
				<el-select v-model="form.status" placeholder="请选择状态">
					<el-option label="正常" :value="0"></el-option>
					<el-option label="异常" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="手机号: ">
				<el-input v-model="form.supplierphone"></el-input>
			</el-form-item>
			<el-form-item label="身份: ">
				<el-input v-model="form.suppliercontacts"></el-input>
			</el-form-item>
			<el-form-item label="预留信息: ">
				<el-input v-model="form.suppliername"></el-input>
			</el-form-item>
			<el-form-item label="地址: ">
				<el-cascader v-model="cityfrom" :options="options" style="width:100%"></el-cascader>
			</el-form-item>
			<el-form-item label="详细地址: ">
				<el-input v-model="form.supplieraddressdetail"></el-input>
			</el-form-item>
			<el-form-item label="创建人: ">
				<el-input v-model="form.creator" readonly disabled></el-input>
			</el-form-item>
			<el-form-item label="医生照片: ">
				<el-upload class="avatar-uploader" name="img" action="https://server.ifufan.com:9090/upload" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
					<img v-if="form.img" :src="form.img" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<div style="height: 20px;"></div>
			<el-form-item>
				<el-button type="danger" @click="onSubmit">确 认 提 交</el-button>
				<el-button @click="resetData">重 置</el-button>
			</el-form-item>
		</el-form>
		
	</el-dialog>
</template>

<script>
import cities from '../assets/js/city.js'
export default {
	name: 'doctorAdd',
	components: {
		//
	},
	props: [],
	data() {
		return {
			dialogAddVisible: false,
			form: {
				name: '', // 医生姓名
				category: '', // 科室
				remark: '', // 描述
				img: '', // 医生照片
				creator: window.sessionStorage.getItem('user'), // 创建人
				status: '', // 状态 0正常1异常
				supplierphone: '', // 手机号
				suppliercontacts: '', // 身份
				suppliername: '', // 预留多余字段
				supplieraddress: '', // 地址
				supplieraddressdetail: '', // 详细地址
			},
			options: cities,
			cityfrom: []
		};
	},
	mounted() {
		//
	},
	methods: {
		onSubmit(){
			if(this.form.name==''){
				this.$message({
					message: '请输入姓名',
					type: 'warning'
				});
				return
			}
			if(this.form.category==''){
				this.$message({
					message: '请输入科室',
					type: 'warning'
				});
				return
			}
			if(this.form.status===''){
				this.$message({
					message: '请选择状态',
					type: 'warning'
				});
				return
			}
			if(!(/^1[3456789]\d{9}$/.test(this.form.supplierphone))){
				this.$message({
					message: '请输入正确的手机号',
					type: 'warning'
				});
				return
			}
			if(this.form.suppliercontacts==''){
				this.$message({
					message: '请输入身份',
					type: 'warning'
				});
				return
			}
			if(this.cityfrom.length<2){
				this.$message({
					message: '请选择地址',
					type: 'warning'
				});
				return
			}
			if(this.form.supplieraddressdetail==''){
				this.$message({
					message: '请输入详细地址',
					type: 'warning'
				});
				return
			}
			if(this.form.img==''){
				this.$message({
					message: '请上传医生照片',
					type: 'warning'
				});
				return
			}
			this.form.supplieraddress = this.cityfrom.join("-");
			this.$axios.post('/devices', this.form).then(res => {
				if(res.status==200 && res.data.code==200){
					this.$message({
						type: 'success',
						message: '添加成功!'
					});
					this.dialogAddVisible = false;
					this.resetData();
					this.$emit("addsuccess");
				}else{
					this.$message({
						type: 'error',
						message: '添加失败!'
					});
				}
			});
		},
		showAdd(){
			this.dialogAddVisible = true;
		},
		resetData(){
			this.form.name = '';
			this.form.category = '';
			this.form.remark = '';
			this.form.img = '';
			this.form.status = '';
			this.form.supplierphone = '';
			this.form.suppliercontacts = '';
			this.form.suppliername = '';
			this.form.supplieraddress = '';
			this.form.supplieraddressdetail = '';
		},
		handleAvatarSuccess(res) {
			this.form.img = "https://server.ifufan.com:9090/"+res.data.filename;
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isPNG = file.type === 'image/png';
			const isGIF = file.type === 'image/gif';
			const isLt2M = file.size / 1024 / 1024 < 1;
			if (!isJPG && !isPNG && !isGIF) {
				this.$message.error('上传头像图片只能是 JPG、PNG、GIF 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 1MB!');
			}
			return isJPG && isLt2M;
		}
	}
}
</script>

<style scoped>
</style>
