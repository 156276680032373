<template>
	<ul class="nav user-menu">
		<li class="nav-item">
			<a href="javascript:void(0);" style="padding: 0px 5px 0px 0px;">
				<span class="user-img">
					<img src="../assets/images/avatar.jpg" :alt="login_user">
				</span>
			</a>
		</li>
		<li class="nav-item">
			<a href="javascript:void(0);" style="font-weight: bold;">
				<span>{{login_user}}</span>
			</a>
		</li>
		<li class="nav-item">
			<a href="javascript:vodi(0);" @click="loginOut">
				<i class="el-icon-switch-button"></i> 退出
			</a>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'uinfo',
	components: {
		//
	},
	props: [],
	data() {
		return {
			login_user: ''
		};
	},
	mounted() {
		this.login_user = window.sessionStorage.getItem('user');
	},
	methods: {
		loginOut(){
			window.sessionStorage.clear();
			this.$router.push({path: '/login'})
		}
	}
}
</script>

<style scoped>
.user-menu {
	float: right;
	margin: 0;
	position: relative;
	z-index: 99;
	display: -webkit-box!important;
	display: -ms-flexbox!important;
	display: flex!important;
}

.nav {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.user-menu.nav>li>a {
	color: #7c7c7c;
	font-size: 14px;
	line-height: 48px;
	padding: 0 20px 0 0;
	height: 50px;
	text-decoration: none;
	font-weight: bold;
}
.user-img {
	display: inline-block;
	margin-right: 3px;
	position: relative;
}
.user-img img {
	width: 32px;
	border-radius: 50%;
	vertical-align: middle;
	border-style: none;
}

</style>
