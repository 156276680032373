<template>
	<div :style="{height: menu_height, overflow:'hidden'}">
		<div class="logo">
			<img src="../assets/images/logo.png" />
		</div>
		<div class="sidebar-menu">
			<ul>
				<li style="color: #ffff" :class="menu0">
					<a href="javascript:;" @click="changeMenu(0)">
						<i class="el-icon-s-custom"></i> 
						<span class="shape1"></span><span class="shape2"></span>
						<span>医生管理</span>
					</a>
				</li>
				<li style="color: #ffff" :class="menu1">
					<a href="javascript:;" @click="changeMenu(1)">
						<i class="el-icon-s-order"></i> 
						<span class="shape1"></span><span class="shape2"></span>
						<span>预约管理</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'cmenu',
	props: {
		menuIndex:{type: Number,default: 0},
	},
	data(){
		return {
			menu0: '',
			menu1: '',
			menu_height: '500px'
		}
	},
	mounted() {
		this['menu'+this.menuIndex] = 'active';
		this.menu_height = window.innerHeight+"px";
	},
	methods:{
		reload(){
			window.location.reload(true);
		},
		changeMenu(ind){
			if(this['menu'+ind]=='active'){
				this.reload();
				return;
			}
			if(ind==0){
				this.$router.push({path:"/"});
			}else if(ind==1){
				this.$router.push({path:"/yuyue"});
			}
		}
	}
}
</script>

<style scoped>
.logo{height: 60px;overflow: hidden;padding: 0px;
	border-bottom: #cc2e28 solid 1px;box-sizing: border-box;}
.logo img{height: 59px;}
.sidebar-menu {
    padding-top: 20px;
    background: #ae1c27;
    height: 100%;
    padding-left: 15px;
}
ul li {
	list-style: none;
}
.sidebar-menu>ul>li {
	margin-bottom: 10px;
	position: relative;
	box-sizing: border-box;
}
.sidebar-menu>ul>li>a {
	border-radius: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	padding: 9px 9px;
	position: relative;
	-webkit-transition: all .2s ease-in-out .1s;
	transition: all .2s ease-in-out .1s;
	height: 48px;
	border-radius: 20px 0 0 20px;
	box-sizing: border-box;
	line-height: 30px;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
	color: #FFFFFF;
}
.sidebar-menu li.active>a {
	color: #000;
	background: #f4f4f4;
	border-radius: 20px 0 0 20px;
}
.sidebar-menu li a:hover,
.sidebar-menu li a:hover i{
	color: #000;
}

.sidebar-menu li.active>a:before {
    content: "";
    position: absolute;
    top: -31px;
    right: 0;
    bottom: 0;
    height: 31px;
	width: 20px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0 0 48px 0;
    z-index: 1;
}
.sidebar-menu li.active>a:after {
    content: "";
    position: absolute;
    top: 48px;
    z-index: 0;
    right: 0;
    bottom: 0;
    height: 30px;
	width: 20px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0 48px 0 0;
}
.sidebar-menu li.active a:after, 
.sidebar-menu li.active a:before {
    border-right: 20px solid #ae1c27;
}
.sidebar-menu li.active a .shape1  {
    position: absolute;
    top: -30px;
    width: 20px;
    height: 60px;
    right: 0;
}
.sidebar-menu li.active a .shape2 {
    position: absolute;
    top: 35px;
    width: 20px;
    height: 30px;
    right: 0;
}
.sidebar-menu li.active a .shape1, 
.sidebar-menu li.active a .shape2  {
    background: #f4f4f4;
}
.sidebar-menu>ul>li>a span {
	-webkit-transition: all .2s ease-in-out 0s;
	transition: all .2s ease-in-out 0s;
	display: inline-block;
	margin-left: 8px;
	white-space: nowrap;
}
.sidebar-menu li a i {
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: #FFFFFF;
	color: #ae1c27;
    padding: 7px 3px;
    border-radius: 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
	display: -webkit-flex;
	-webkit-align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-justify-content: center;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: ease all .5s;
}
.sidebar-menu li.active a i {
    background: #ae1c27;
    color: #fff;
}
*, ::before, ::after{box-sizing: border-box;}
</style>
